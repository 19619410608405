import { formatCurrency } from "@common/helpers/numberFormatting";
import { ProductCoverage } from "@modules/purchase/types/ExtendedWarrantyRates";
import Link from "next/link";
import { useRouter } from "next/router";
import { Trans, useTranslation } from "react-i18next";

type Props = {
  coverage: ProductCoverage;
};

const CostPerMonthCoverage = ({ coverage }: Props) => {
  const { t } = useTranslation(["common", "purchase"]);
  const locale = useRouter().locale || "en";

  return (
    <div className="w-full rounded-md mb-2 bg-gray-25 p-4">
      <div className="flex items-center mb-2">
        <Trans
          t={t}
          i18nKey="common:a_value_of"
          values={{
            value: formatCurrency(coverage.costPerMonth || 0, locale, {
              showCents: "never",
            }),
          }}
          components={{
            label: <span className="body-3 text-blueZodiac" />,
            bold: (
              <span className="ml-2 text-center text-4xl font-semibold leading-10" />
            ),
          }}
        />
      </div>
      <div className="caption-1 font-medium">
        <Trans
          t={t}
          i18nKey="purchase:affirm_payment_plans_are_available"
          components={{
            affirmLink: (
              <Link
                className="text-primary-bold underline"
                href="https://www.affirm.com/en-ca/"
                target="_blank"
              />
            ),
          }}
        />
      </div>
    </div>
  );
};

export default CostPerMonthCoverage;
