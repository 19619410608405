import api from "@modules/api/api";
import { useMutation } from "@tanstack/react-query";

export const decodeErrorResponse = {
  invalidVin: "Invalid VIN",
};

type PostBody = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  year?: number | null;
  make?: string | null;
  model?: string | null;
  trim?: string | null;
  vin?: string | null;
  odometer: number;
  province: string;
  isConsentGiven: boolean;
  source?: string | null;
  vehicleCoverImage?: string | null;
  sourceAdId?: string | null;
  sourceAppId?: string | null;
  sourceLocationId?: string | null;
  sourceSessionId?: string | null;
  sourceUserId?: string | null;
  sourceCtaId?: string | null;
  utmSource?: string | null;
  utmMedium?: string | null;
  utmCampaign?: string | null;
  utmContent?: string | null;
};

type CreateAftermarketLeadRequest = {
  success: boolean;
  message: string;
  decodedVin?: string;
};

const performPost = async (postBody: PostBody) => {
  const response = await api.post<CreateAftermarketLeadRequest>(
    "api/purchases/aftermarket-lead",
    {
      json: postBody,
    }
  );
  return response;
};

export default function useCreateAftermarketLeadMutation() {
  return useMutation({ mutationFn: performPost });
}
