import api from "@modules/api/api";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { useRouter } from "next/router";
import purchaseQueryKeys from "../purchaseQueryKeys";
import { Purchase, PurchaseNotFoundResponse } from "../types/Purchase";

const getActiveAftermarketPurchase = (locale: string) => {
  return api.get<Purchase | PurchaseNotFoundResponse>(
    "api/purchases/active-aftermarket",
    {
      headers: { "Accept-Language": locale },
    }
  );
};

export default function useAftermarketPurchase() {
  const router = useRouter();
  const locale = router.locale || "en";
  const {
    data: purchase,
    isLoading,
    isFetching,
    isStale,
    refetch,
  } = useQuery({
    queryKey: purchaseQueryKeys.activeAftermarketPurchase(),
    queryFn: () => getActiveAftermarketPurchase(locale),
    placeholderData: keepPreviousData,
    meta: { overrideDefaultErrorHandling: true },
  });

  return {
    purchase,
    isLoading,
    isFetching,
    isStale,
    refetch,
  };
}
