import Modal, { Props as ModalProps } from "@common/components/Modal";
import { ICO_INQUIRY_EMAIL } from "@common/constants";
import { useTranslation } from "next-i18next";
import TextLink from "@common/components/TextLink";

type Props = Pick<ModalProps, "isOpen" | "onClose">;

const DealCreatedForOfferModal = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation(["common", "purchase", "tradeInPage"]);
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t("tradeInPage:instant_cash_offer")}
      alignment="center"
    >
      <div className="md:w-96 mx-auto">
        <h1 className="mb-4 text-primary-bold">
          {t("purchase:your_offer_in_progress")}
        </h1>
        <h3 className="pb-6 text-primary-deep">
          {t("purchase:offer_processed_by_buying_team")}
        </h3>
        <p className="body-2 mt-1 text-gray-600">
          {t("purchase:contact_us_offer_questions")}
        </p>
        <TextLink
          className="text-gray-600"
          href={`mailto:${ICO_INQUIRY_EMAIL}`}
        >
          <a>{ICO_INQUIRY_EMAIL}</a>
        </TextLink>
      </div>

      <Modal.Buttons
        isPrimarySubmit
        primaryButtonText={t("common:contact_us")}
        onPrimaryClick={() => {
          window.location.href = `mailto:${ICO_INQUIRY_EMAIL}`;
          onClose();
        }}
        secondaryButtonText={t("common:cancel")}
      />
    </Modal>
  );
};

export default DealCreatedForOfferModal;
