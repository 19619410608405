import Button from "@common/components/Button";
import { ArrowRightIcon, CheckIcon } from "@common/components/icons";
import { formatCurrency, formatNumber } from "@common/helpers/numberFormatting";
import useCoverageConfig from "@modules/purchase/hooks/useCoverageConfig";
import {
  ExtendedWarrantyCoverageDetails,
  ExtendedWarrantyRatesResponse,
  ProductCoverage,
} from "@modules/purchase/types/ExtendedWarrantyRates";
import classNames from "classnames";
import { useRouter } from "next/router";
import { Trans, useTranslation } from "next-i18next";
import { dateTimeFormatter } from "@common/helpers/dateTimeHelper";
import { LocalDate, convert } from "@js-joda/core";
import { ExtendedWarrantyPremiumRightRideDigital } from "@modules/purchase/types/IAProgram";
import { CoverageSearchBy } from "./AvailableCoveragesModal";
import CostPerMonthCoverage from "./CostPerMonthCoverage";

type CoverageSelectionProps = {
  coverages?: ExtendedWarrantyRatesResponse;
  onSelectCoverage: (
    coverage: ProductCoverage,
    selectedCoverageDetails: ExtendedWarrantyCoverageDetails
  ) => void;
  onQuoteDifferentVehicle: (searchType?: CoverageSearchBy) => void;
  showEstimateMessaging: boolean;
  disabled: boolean;
  viewMode?: "quote" | null | undefined;
};

const WarrantySelectCoverageCards = ({
  coverages,
  onSelectCoverage,
  onQuoteDifferentVehicle,
  showEstimateMessaging,
  disabled,
  viewMode,
}: CoverageSelectionProps) => {
  const isQuoteMode = viewMode === "quote";
  const { t } = useTranslation(["common", "purchase"]);
  const locale = useRouter().locale || "en";

  const coverageConfig = useCoverageConfig(coverages?.coverages, locale);

  if (!coverages) return null;

  const coverageItem = coverages;

  const isPremiumCoverage =
    coverageItem.productDetails.programId ===
    ExtendedWarrantyPremiumRightRideDigital.id;

  const isPremiumCoverageWithoutSuperiorWrap =
    coverageItem.productDetails.programId ===
      ExtendedWarrantyPremiumRightRideDigital.id &&
    coverageItem.coverages.every((x) => x.type !== "SW");

  return (
    <div className="flex max-w-xl mx-auto flex-col-reverse text-center gap-y-4 sm:gap-y-10 gap-x-4 sm:gap-x-6 3md:grid 3md:grid-cols-3 3md:gap-4 3md:justify-center 3md:max-w-none">
      {coverageItem.coverages.map((coverage, index) => {
        const config =
          coverageConfig[coverage.type as keyof typeof coverageConfig];

        const isHighlightedCoverage =
          index === coverageItem.coverages.length - 1;

        return (
          <div
            key={`${coverage.type}-${coverage.term}`}
            className="3md:max-w-[356px] lg:max-w-full"
          >
            <div
              className={classNames(
                "relative overflow-hidden group shadow-elevation-01 flex flex-col md:flex-col justify-between items-center p-4 md:p-6 rounded-2xl w-full text-text-light-100 bg-white transition h-full",
                {
                  "border border-1 border-primary-bold-500":
                    isHighlightedCoverage,
                }
              )}
            >
              {isHighlightedCoverage && (
                <div className="absolute bg-primary-bold w-60 h-8 -right-12 top-7 flex justify-center items-center rotate-[30deg]">
                  <div className="text-white text-sm font-semibold leading-none px-12 uppercase">
                    {isPremiumCoverageWithoutSuperiorWrap
                      ? t("purchase:most_coverage")
                      : t("purchase:best_value")}
                  </div>
                </div>
              )}

              {/** Details Div */}
              <div
                className={classNames("flex flex-col text-left px-3 w-full")}
              >
                <h5
                  className={classNames("pb-2", {
                    "text-primary-bold max-w-[65%]": isHighlightedCoverage,
                  })}
                >
                  {config.type({ index: index + 1 })}
                </h5>
                <p className="text-lg font-medium text-gray-500">
                  {isPremiumCoverage
                    ? t("purchase:youre_covered_until")
                    : t("purchase:youre_covered_for")}
                </p>
                <p className="text-[1.22rem] font-semibold text-primary-bold">
                  {config.termDescription(
                    {
                      years: coverage.term / 12,
                      months: coverage.term,
                      kms:
                        coverage.termKm === "999999"
                          ? t("common:unlimited")
                          : formatNumber(
                              parseInt(coverage.termKm ?? "0", 10),
                              locale
                            ),
                      yearString:
                        coverage.term === 12
                          ? t("common:year").toLowerCase()
                          : t("common:years").toLowerCase(),
                      termExpiryDate: coverage.termExpiryDate
                        ? dateTimeFormatter(
                            convert(
                              LocalDate.parse(
                                coverage.termExpiryDate.toString()
                              )
                            ).toDate(),
                            locale,
                            "MMMM, YYYY"
                          )
                        : undefined,
                    },
                    true
                  )}
                </p>

                <div className="flex items-center gap-1 mt-1 mb-3">
                  <span className="text-[1.2rem] text-blueZodiac">
                    {formatCurrency(coverage.price, locale, {
                      showCents: "never",
                    })}
                  </span>
                  <p className="caption-3 text-gray-300">
                    {t("purchase:due_at_checkout").toLowerCase()}*
                  </p>
                </div>

                <div className="my-3">
                  {config.details.map((detail, idx) => (
                    <div
                      // these indices are static so it's fine here
                      // eslint-disable-next-line react/no-array-index-key
                      key={`${detail}-${idx}`}
                      className="flex px-2 py-1 items-center"
                    >
                      <CheckIcon
                        className="h-5 w-5 mr-2 text-primary-bold flex-shrink-0"
                        aria-hidden="true"
                        preserveAspectRatio="xMidYMid meet"
                      />
                      <span className="caption-2 flex-grow">{detail}</span>
                    </div>
                  ))}
                </div>
              </div>

              {/** Button Div */}
              <div className="flex flex-col sm:gap-2 mt-auto text-left px-3 w-full">
                <CostPerMonthCoverage coverage={coverage} />

                {showEstimateMessaging && (
                  <div className="flex items-center gap-1 mb-5">
                    <p className="caption-1 leading-5 text-gray-300">
                      <Trans
                        t={t}
                        i18nKey="purchase:coverage_options_and_prices"
                        components={{
                          bold: <span className="text-gray-500" />,
                          a: (
                            <button
                              className="underline text-primary-bold"
                              onClick={() => onQuoteDifferentVehicle("vin")}
                              type="button"
                              disabled={disabled}
                            >
                              link
                            </button>
                          ),
                        }}
                      />
                    </p>
                  </div>
                )}
                {!isQuoteMode && (
                  <>
                    <Button
                      rightIcon={
                        <ArrowRightIcon className="h-4 inline-block" />
                      }
                      className="mb-2"
                      onClick={() => onSelectCoverage(coverage, coverageItem)}
                      disabled={disabled}
                      fill="filled"
                    >
                      {t("purchase:select_this_coverage")}
                    </Button>
                    <Button
                      fill="link"
                      className="underline p-2"
                      disabled={disabled}
                      onClick={() => onQuoteDifferentVehicle()}
                    >
                      {t("purchase:quote_different_vehicle")}
                    </Button>
                  </>
                )}

                {config.caption && (
                  <p className="caption-3">
                    {config.caption({
                      term: coverage.term,
                      termKm: `${formatNumber(
                        parseInt(coverage.termKm ?? "", 10),
                        locale
                      )} KM`,
                      deductible: formatCurrency(
                        parseInt(coverage.deductible ?? "", 10),
                        locale,
                        {
                          showCents: "never",
                        }
                      ),
                    })}
                  </p>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default WarrantySelectCoverageCards;
