import useAftermarketPurchaseFromQuery from "@modules/purchase/hooks/useAftermarketPurchaseFromQuery";
import { useRouter } from "next/router";
import useSourceInfoFromQuery from "@modules/query-string/hooks/useSourceInfoFromQuery";
import { WarrantySelectCoverageLayout } from "@modules/cms/types/WarrantySelectCoverageLayout";
import GetBackgroundStyle from "@modules/cms/helpers/getBackgroundStyle";
import { Trans, useTranslation } from "next-i18next";
import LocalImage from "@common/components/LocalImage";
import {
  BubblesWarrantyEn,
  BubblesWarrantyFr,
  WarrantyHeroEn,
  WarrantyHeroFr,
} from "@public/images/purchase/coverage";
import { ImageWithTranslation } from "@common/types/ImageWithTranslation";
import SlideToggle from "@common/components/SlideToggle";
import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { ArrowRightIcon, CarIcon, PersonIcon } from "@common/components/icons";
import Form from "@common/components/form";
import Button from "@common/components/Button";
import Listbox from "@common/components/form/Listbox";
import { Province, provinces } from "@common/types/Province";
import customerValidation from "@common/helpers/validations/customerValidation";
import vehicleValidation from "@common/helpers/validations/vehicleValidation";
import Link from "next/link";
import useDebounce from "@common/hooks/useDebounce";
import {
  useCbbVehicleLookupByNameByUniqueTrim,
  getCondensedCbbVehicleName,
} from "@modules/vehicle/hooks/useCbbVehicleLookup";
import {
  useNullableNumberMask,
  usePhoneNumberMask,
} from "@common/hooks/useMask";
import Combobox from "@common/components/form/Combobox";
import { CondensedCbbVehicle } from "@modules/vehicle/types/VehicleLookupResponse";
import useCreateAftermarketLeadMutation, {
  decodeErrorResponse,
} from "@modules/purchase/hooks/useCreateAftermarketLeadMutation";
import OverlayLoader from "@common/components/OverlayLoader";
import { provinceFilters } from "@modules/purchase/hooks/useEligibleProvinces";
import useProvincesStatusBySourceList from "@modules/purchase/hooks/useProvincesStatusBySourceList";
import { gtmPushData } from "@common/helpers/gtm";
import { Event as HotjarEvent, hotjar } from "@modules/core/hotjar/types";
import useUser from "@modules/user/hooks/useUser";
import useVehicleDisplayFuelTypes from "@modules/vehicle/hooks/useVehicleDisplayFuelTypes";
import translate from "@common/helpers/translate";
import { CONDITIONALLY_SUCCESSFUL_CHROME_DECODE } from "@common/constants";
import { VinInput } from "@common/components/form/vehicle";
import PhoneInput from "@common/components/form/PhoneInput";
import useAftermarketPurchase from "@modules/purchase/hooks/useAftermarketPurchase";
import { tryGetActiveAftermarketPurchase } from "@modules/purchase/types/Purchase";
import {
  aftermarketDetailsStep,
  getStepById,
} from "@modules/purchase/types/PurchaseStep";
import useUtmParamsFromQuery from "@modules/query-string/hooks/useUtmParamsFromQuery";
import PurchaseSourceVehicleImage from "../PurchaseSourceVehicleImage";

const bubblesImage: ImageWithTranslation = {
  srcEn: BubblesWarrantyEn,
  srcFr: BubblesWarrantyFr,
  altKey: "purchase:alt_wty_info_img",
};

const vehicleDefaultImage: ImageWithTranslation = {
  srcEn: WarrantyHeroEn,
  srcFr: WarrantyHeroFr,
  altKey: "purchase:alt_wty_vehicle_img",
};

type Props = {
  layout: WarrantySelectCoverageLayout;
};

type FormProps = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  province?: Province & { isEnabled: boolean };
  yearMakeModel?: CondensedCbbVehicle;
  vin?: string;
  odometer: string | null;
  consent: boolean;
  fuelType?: { id: number; name: string };
};

const WarrantyQuoteForm = ({ layout: { background } }: Props) => {
  const { t } = useTranslation(["common", "purchase"]);
  const router = useRouter();
  const { locale: rlocale, query } = router;
  const locale = rlocale || "en";
  const { user } = useUser();

  const aftermarketPurchaseFromQuery = useAftermarketPurchaseFromQuery();
  const { source: querySource } = useSourceInfoFromQuery();

  const { purchase: purchaseResponse } = useAftermarketPurchase();
  const purchase = tryGetActiveAftermarketPurchase(purchaseResponse);

  const {
    firstName,
    lastName,
    email,
    phone,
    province: buyerProvince,
    vin: buyerVin,
    mileage,
    coverImage,
    sourceAdId,
    sourceUserId,
    sourceSessionId,
    sourceAppId,
    sourceLocationId,
    sourceCtaId,
    year,
    make,
    model,
    trim,
    fuelType,
  } = aftermarketPurchaseFromQuery;

  const [formMode, setFormMode] = useState<"ymm" | "vin">("ymm");

  const provincesList = useProvincesStatusBySourceList(
    querySource,
    provinceFilters.isAftermarketEnabled
  );

  const { fuelTypes } = useVehicleDisplayFuelTypes();
  const fuelTypeOptions: { id: number; name: string }[] | null = useMemo(
    () =>
      fuelTypes.length > 0
        ? fuelTypes.map(({ id, name }) => ({
            id,
            name: translate(name, locale),
          }))
        : null,
    [fuelTypes, locale]
  );

  const { unMaskPipe: phoneUnMaskPipe } = usePhoneNumberMask();
  const { maskPipe: numberMaskPipe, unMaskPipe: numberUnMaskPipe } =
    useNullableNumberMask();

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    reset,
    formState: { errors },
    getValues,
    setError,
  } = useForm<FormProps>({
    values: {
      firstName: firstName ?? "",
      lastName: lastName ?? "",
      email: email ?? "",
      phone: phone ?? "",
      province: buyerProvince
        ? provincesList.find((p) => p.abbr === buyerProvince)
        : undefined,
      vin: buyerVin ?? "",
      odometer: mileage ? numberMaskPipe(mileage.toString()) : "",
      consent: false,
      yearMakeModel:
        make && model && year
          ? {
              year,
              make,
              model,
              trim: trim ?? undefined,
              name: getCondensedCbbVehicleName(year, make, model, trim),
            }
          : undefined,
      fuelType: fuelType
        ? fuelTypeOptions?.find((ft) => ft.name === fuelType)
        : undefined,
    },
  });

  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    if (router.isReady && firstLoad) {
      reset({
        firstName: firstName ?? "",
        lastName: lastName ?? "",
        email: email ?? "",
        phone: phone ?? "",
        province: buyerProvince
          ? provinces.find((p) => p.abbr === buyerProvince)
          : undefined,
        vin: buyerVin ?? "",
        odometer: mileage ? numberMaskPipe(mileage.toString()) : "",
        consent: false,
        yearMakeModel:
          make && model && year
            ? {
                year,
                make,
                model,
                trim: trim ?? undefined,
                name: getCondensedCbbVehicleName(year, make, model, trim),
              }
            : undefined,
        fuelType: fuelType
          ? fuelTypeOptions?.find((ft) => ft.name === fuelType)
          : undefined,
      });
      if (buyerVin) {
        setFormMode("vin");
      }
      setFirstLoad(false);
    }
  }, [
    buyerProvince,
    buyerVin,
    email,
    firstLoad,
    firstName,
    lastName,
    make,
    mileage,
    model,
    numberMaskPipe,
    phone,
    reset,
    router.isReady,
    trim,
    year,
    fuelType,
    fuelTypeOptions,
  ]);

  // hack: on locale change, re-run the number mask
  useEffect(() => {
    const v = getValues("odometer");
    if (v != null) {
      setValue("odometer", numberMaskPipe(v.replace(/[^\d.]/, "")));
    }
  }, [getValues, numberMaskPipe, router.locale, setValue]);

  const createAftermarketLeadMutation = useCreateAftermarketLeadMutation();
  const utmParams = useUtmParamsFromQuery();

  const [showOverlay, setShowOverlay] = useState(false);

  const handleFormSubmit = ({
    firstName: formFirstName,
    lastName: formLastName,
    phone: formPhone,
    email: formEmail,
    province,
    odometer,
    vin,
    yearMakeModel,
    fuelType: formFuelType,
    consent,
  }: FormProps) => {
    if (province && odometer) {
      setShowOverlay(true);
      createAftermarketLeadMutation.mutate(
        {
          firstName: formFirstName,
          lastName: formLastName,
          phone: phoneUnMaskPipe(formPhone),
          email: formEmail,
          province: province.abbr,
          odometer: numberUnMaskPipe(odometer) ?? 0,
          source: querySource,
          vin: formMode === "vin" ? vin ?? null : null,
          year: formMode === "ymm" ? yearMakeModel?.year ?? null : null,
          make: formMode === "ymm" ? yearMakeModel?.make ?? null : null,
          model: formMode === "ymm" ? yearMakeModel?.model ?? null : null,
          trim: formMode === "ymm" ? yearMakeModel?.trim ?? null : null,
          isConsentGiven: consent,
          sourceAdId,
          sourceUserId,
          sourceSessionId,
          sourceAppId,
          sourceLocationId,
          sourceCtaId,
          vehicleCoverImage: coverImage,
          ...utmParams,
        },
        {
          onSuccess: (data) => {
            gtmPushData({
              event: "AftermarketPurchase",
              element: "Landing Page",
              description: "Free Instant Quote",
              user_id: user?.id,
            });
            hotjar.event(HotjarEvent.AftermarketPurchaseFreeInstantQuote);

            let decodedVin: string | undefined = "";

            if (
              data &&
              data.decodedVin &&
              data.success === true &&
              data.message === CONDITIONALLY_SUCCESSFUL_CHROME_DECODE
            ) {
              decodedVin = data.decodedVin;
            }

            if (
              data &&
              data.success === false &&
              data.message === decodeErrorResponse.invalidVin
            ) {
              setError("vin", {
                message: t("common:invalid_vin_please_try_again"),
              });

              setShowOverlay(false);

              return;
            }

            // we want to keep the query params as they were passed to us so the next
            // page behaves as it always has (except loginSuccessful)
            const { loginSuccessful, ...restOfQuery } = query;
            router.push({
              pathname: "/purchase/coverage/quote",
              query: {
                ...restOfQuery,
                vin:
                  formMode === "vin"
                    ? decodedVin || vin || undefined
                    : undefined,
                year:
                  formMode === "ymm"
                    ? yearMakeModel?.year ?? undefined
                    : undefined,
                make:
                  formMode === "ymm"
                    ? yearMakeModel?.make ?? undefined
                    : undefined,
                model:
                  formMode === "ymm"
                    ? yearMakeModel?.model ?? undefined
                    : undefined,
                trim:
                  formMode === "ymm"
                    ? yearMakeModel?.trim ?? undefined
                    : undefined,
                fuelType:
                  formMode === "ymm" && formFuelType
                    ? formFuelType?.name
                    : undefined,
                buyerProvince: province.abbr,
                buyerFirst: formFirstName,
                buyerLast: formLastName,
                buyerEmail: formEmail,
                buyerPhoneNumber: phoneUnMaskPipe(formPhone),
                odometer: numberUnMaskPipe(odometer) ?? 0,
              },
            });
          },
          onError: () => {
            setShowOverlay(false);
          },
        }
      );
    }
  };

  const [ymmQuery, setYmmQuery] = useState<string>("");

  const debouncedQuery = useDebounce(ymmQuery, 300);
  const { vehicles: vehiclesFound } =
    useCbbVehicleLookupByNameByUniqueTrim(debouncedQuery);

  const showResumeButton = !!purchase && purchase.currentStepId != null;

  const handleOnResumePurchaseClick = () => {
    if (!purchase) return;

    const purchaseStep = getStepById(
      "aftermarket",
      purchase?.currentStepId ?? aftermarketDetailsStep.id
    );

    if (purchaseStep) {
      router.push({
        pathname: purchaseStep.href,
      });
    }
  };

  return (
    <OverlayLoader isLoading={showOverlay}>
      <div style={GetBackgroundStyle(background)}>
        <div className="relative max-w-page mx-auto flex gap-4 items-center flex-col pt-10 pb-6 px-4 lg:px-5 lg:py-16 lg:flex-row">
          <div className="flex flex-col lg:basis-[47%]">
            <div className="flex-1 w-full relative">
              <div className="min-w-full h-auto relative inset-0">
                {coverImage ? (
                  <div>
                    <div className="absolute -right-4 md:-right-1 lg:-right-14 xl:-right-10 z-[2]">
                      <LocalImage
                        className="h-48 w-40 md:h-96 md:w-96 object-contain rounded-lg"
                        src={
                          locale === "fr"
                            ? bubblesImage.srcFr
                            : bubblesImage.srcEn
                        }
                        alt={t(bubblesImage.altKey)}
                      />
                    </div>

                    <div className="flex justify-center overflow-hidden relative md:h-full">
                      <div className="rounded-full border-white border-[.5rem] mr-9 md:mr-32 lg:mr-44 h-80 w-80 md:border-[1rem] md:h-[27rem] md:w-[27rem] lg:h-[30rem] lg:w-[30rem]">
                        <PurchaseSourceVehicleImage
                          className="rounded-full w-full h-full object-cover"
                          src={coverImage}
                          alt={t(vehicleDefaultImage.altKey)}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="overflow-auto relative h-56 md:h-96 lg:mr-4">
                    <LocalImage
                      className="z-[2] w-full object-contain h-auto rounded-lg"
                      src={
                        locale === "fr"
                          ? vehicleDefaultImage.srcFr
                          : vehicleDefaultImage.srcEn
                      }
                      alt={t(vehicleDefaultImage.altKey)}
                      fill
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="flex-1 lg:basis-[40%] lg:px-0 md:px-28">
              <div className="w-full items-center justify-center text-center lg:text-left lg:items-start lg:justify-start">
                <p className="text-text-light-100 font-semibold text-[2.25rem] md:text-[3.25rem] leading-[2.625rem] md:leading-[3.625rem]">
                  <Trans
                    t={t}
                    i18nKey="purchase:protect_your_ride_and_wallet"
                    components={[<span className="text-primary-bold" />]}
                  />
                </p>
              </div>

              <div className="flex flex-col gap-4 mt-4 lg:mt-6 w-full items-center justify-center text-center lg:text-left lg:items-start lg:justify-start">
                <h4>
                  <Trans
                    t={t}
                    i18nKey="purchase:get_instant_quote_in_seconds"
                    components={[<span className="font-medium" />]}
                  />
                </h4>
                <p className="body-2">
                  {t("purchase:right_ride_is_a_trusted")}
                </p>
              </div>
            </div>
          </div>
          <div className="bg-gray-25 rounded-2xl py-8 px-6 flex-1 basis-[53%]">
            <form onSubmit={handleSubmit(handleFormSubmit)}>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
                <div className="sm:col-span-2">
                  <SlideToggle
                    leftLabel={t("common:year_make_model")}
                    leftValue="ymm"
                    rightLabel={t("common:vin")}
                    rightValue="vin"
                    value={formMode}
                    onChange={setFormMode}
                  />
                </div>
                <div className="flex flex-col gap-3">
                  <div className="flex items-center gap-2">
                    <span className="p-1 bg-white rounded-full text-2xl">
                      <PersonIcon className="text-gray-300" />
                    </span>
                    {t("common:your_info")}
                  </div>
                  <Form.Input
                    label={t("common:first_name")}
                    maxLength={customerValidation.firstName.maxLength}
                    feedback={
                      errors.firstName?.type === "required" && (
                        <p className="text-error-500">
                          {t("common:form_error_required_first_name")}
                        </p>
                      )
                    }
                    {...register("firstName", {
                      required: true,
                      validate: customerValidation.firstName.valid,
                      maxLength: customerValidation.firstName.maxLength,
                    })}
                  />
                  <Form.Input
                    label={t("common:last_name")}
                    maxLength={customerValidation.lastName.maxLength}
                    feedback={
                      errors.lastName?.type === "required" && (
                        <p className="text-error-500">
                          {t("common:form_error_required_last_name")}
                        </p>
                      )
                    }
                    {...register("lastName", {
                      required: true,
                      validate: customerValidation.lastName.valid,
                      maxLength: customerValidation.lastName.maxLength,
                    })}
                  />
                  <Form.Input
                    label={t("common:email")}
                    maxLength={customerValidation.email.maxLength}
                    feedback={
                      errors.email?.type === "required" && (
                        <p className="text-error-500">
                          {t("common:form_error_required_email")}
                        </p>
                      )
                    }
                    {...register("email", {
                      required: true,
                      pattern: customerValidation.email.pattern,
                      maxLength: customerValidation.email.maxLength,
                    })}
                  />
                  <PhoneInput
                    label={t("common:phone")}
                    feedback={errors.phone?.message}
                    status={errors.phone ? "error" : "default"}
                    {...register("phone", {
                      required: {
                        value: true,
                        message: t("common:form_error_required_phone_number"),
                      },
                      validate: {
                        minLength: (value) =>
                          customerValidation.phoneNumber.valid(
                            phoneUnMaskPipe(value)
                          ) || t("common:phone_min_length"),
                        validateAreaCode: (value) =>
                          customerValidation.phoneNumber.validAreaCode(
                            phoneUnMaskPipe(value)
                          ) || t("common:form_error_invalid_phone_number"),
                      },
                    })}
                  />
                  <Controller
                    name="province"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, ref } }) => (
                      <Listbox
                        label={t("common:province")}
                        onChange={onChange}
                        selectedItem={watch("province")}
                        items={provincesList}
                        keyOption={(p) => p.id}
                        displayOption={(item) =>
                          item.isEnabled
                            ? t(item.name)
                            : `${t(item.name)} - ${t("common:coming_soon")}`
                        }
                        displayButton={(item) =>
                          item?.name != null
                            ? t(item.name)
                            : t("common:select_province")
                        }
                        disabledOption={(item) => !item.isEnabled}
                        valueOption={(p) => p}
                        buttonRef={ref}
                        feedback={
                          errors.province?.type === "required" && (
                            <p className="text-error-500">
                              {t("common:province_required")}
                            </p>
                          )
                        }
                      />
                    )}
                  />
                </div>
                <div className="flex flex-col gap-3">
                  <div className="flex items-center gap-2">
                    <span className="p-1 bg-white rounded-full text-2xl">
                      <CarIcon className="text-gray-300" />
                    </span>
                    {t("common:vehicle_info")}
                  </div>
                  {formMode === "ymm" && (
                    <>
                      <Controller
                        name="yearMakeModel"
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { value, onChange } }) => (
                          <Combobox
                            label={t("common:year_make_and_model")}
                            items={vehiclesFound}
                            keyOption={(item) => item.name}
                            displayOption={(item) => item?.name ?? ""}
                            displayButton={(item) => item?.name ?? ""}
                            valueOption={(item) => item}
                            selectedValue={value}
                            onChange={onChange}
                            onInputChange={setYmmQuery}
                            hideDropdownBtn
                            feedback={
                              errors.yearMakeModel?.type === "required" && (
                                <p className="text-error-500">
                                  {t("common:vehicle_description_required")}
                                </p>
                              )
                            }
                            itemsFilter="none"
                            itemsTextDisplay="full"
                          />
                        )}
                      />
                      {fuelTypeOptions && (
                        <Controller
                          name="fuelType"
                          control={control}
                          rules={{ required: true }}
                          render={({ field: { onChange, ref } }) => (
                            <Listbox
                              label={t("common:fuel_type")}
                              onChange={onChange}
                              selectedItem={watch("fuelType")}
                              items={fuelTypeOptions}
                              displayOption={(item) => item?.name ?? ""}
                              displayButton={(item) =>
                                item?.name || t("common:select_fuel_type")
                              }
                              keyOption={(p) => p.id}
                              valueOption={(p) => p}
                              buttonRef={ref}
                              feedback={
                                errors.fuelType?.type === "required" && (
                                  <p className="text-error-500">
                                    {t("common:fuel_type_required")}
                                  </p>
                                )
                              }
                            />
                          )}
                        />
                      )}
                    </>
                  )}
                  {formMode === "vin" && (
                    <VinInput
                      name="vin"
                      labelOption="short"
                      register={register}
                      errors={errors}
                      required
                    />
                  )}
                  <Form.Input
                    label={t("common:odometer")}
                    rightIcon={
                      <span className="text-light-200 body-3 font-medium">
                        KM
                      </span>
                    }
                    maxLength={vehicleValidation.odometer.maxLength}
                    feedback={
                      errors.odometer?.message != null && (
                        <p className="text-error-500">
                          {errors.odometer?.message}
                        </p>
                      )
                    }
                    {...register("odometer", {
                      required: {
                        value: true,
                        message: t("common:form_error_required_odometer"),
                      },
                      maxLength: vehicleValidation.odometer.maxLength,
                      min: {
                        value: 1,
                        message: t("purchase:odometer_cannot_be_zero"),
                      },
                      onChange: (e) => {
                        e.target.value = numberMaskPipe(e.target.value);
                      },
                    })}
                  />
                  <Form.Checkbox
                    label={
                      <span className="caption-1 font-medium">
                        <Trans
                          t={t}
                          i18nKey="purchase:customer_details_terms_and_conditions"
                          components={{
                            a: (
                              <Link
                                className="text-primary-bold underline"
                                href="/privacy-policy"
                                target="_blank"
                              />
                            ),
                            b: (
                              <Link
                                className="text-primary-bold underline"
                                href="/terms-conditions"
                                target="_blank"
                              />
                            ),
                          }}
                        />
                      </span>
                    }
                    feedback={
                      errors.consent?.type === "required" && (
                        <p className="text-error-500">
                          {t("common:form_error_required_consent")}
                        </p>
                      )
                    }
                    {...register("consent", { required: true })}
                  />
                  {!showResumeButton && (
                    <>
                      <div className="hidden sm:block text-center">
                        <Button
                          type="submit"
                          className="uppercase"
                          size="extra-large"
                          rightIcon={<ArrowRightIcon />}
                        >
                          {t("purchase:get_my_free_quote")}
                        </Button>
                      </div>
                      <div className="block sm:hidden text-center">
                        <Button
                          type="submit"
                          className="uppercase w-full"
                          rightIcon={<ArrowRightIcon />}
                        >
                          {t("purchase:get_my_free_quote")}
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              </div>

              {showResumeButton && (
                <div className="flex flex-col items-center space-y-4 mt-6">
                  <div className="hidden sm:block text-center">
                    <Button
                      type="submit"
                      className="uppercase"
                      size="extra-large"
                      rightIcon={<ArrowRightIcon />}
                    >
                      {t("purchase:get_my_free_quote")}
                    </Button>
                  </div>
                  <div className="block sm:hidden text-center w-full">
                    <Button
                      type="submit"
                      className="uppercase w-full"
                      rightIcon={<ArrowRightIcon />}
                    >
                      {t("purchase:get_my_free_quote")}
                    </Button>
                  </div>
                  <Button
                    rightIcon={<ArrowRightIcon />}
                    onClick={handleOnResumePurchaseClick}
                    buttonStyle="borderless"
                    fill="link"
                    spacing="no-padding"
                    className="text-primary-bold underline hover:no-underline"
                  >
                    {t("purchase:resume_my_purchase")}
                  </Button>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </OverlayLoader>
  );
};

export default WarrantyQuoteForm;
