import { useTranslation } from "next-i18next";
import Form from "@common/components/form";
import { Controller, SubmitHandler, useFormContext } from "react-hook-form";
import DatePicker from "@common/components/form/DatePicker";
import { useRouter } from "next/router";
import { LocalDate } from "@js-joda/core";
import customerValidation from "@common/helpers/validations/customerValidation";
import { usePhoneNumberMask } from "@common/hooks/useMask";
import SubStepButtons from "@common/components/form/SubStepButtons";
import PhoneInput from "@common/components/form/PhoneInput";
import {
  FinancingPreQualificationMode,
  introductionMode,
  residenceinfoMode,
} from "./types/FinancingPreQualificationMode";
import { PreQualificationFormProps } from "./types/FinancingPreQualificationFormProps";

type Props = {
  onChangeMode(newMode: FinancingPreQualificationMode): void;
};

const FinancingPreQualificationPersonalInfo = ({ onChangeMode }: Props) => {
  const { t } = useTranslation(["common"]);
  const { locale } = useRouter();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useFormContext<PreQualificationFormProps>();

  const { unMaskPipe: phoneUnMaskPipe } = usePhoneNumberMask();

  const onFormSubmit: SubmitHandler<PreQualificationFormProps> = () => {
    onChangeMode(residenceinfoMode);
  };

  const onBack: () => void = () => {
    onChangeMode(introductionMode);
  };

  return (
    <div className="mx-auto mb-5 lg:w-5/6 md:w-3/4 sm:w-4/5">
      <div>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <div className="flex flex-col gap-3">
            <Form.Input
              label={t("common:first_name")}
              maxLength={customerValidation.firstName.maxLength}
              feedback={
                errors.firstName && (
                  <p className="text-error-500">{errors.firstName.message}</p>
                )
              }
              {...register("firstName", {
                required: { value: true, message: t("common:required") },
                validate: customerValidation.firstName.valid,
                maxLength: customerValidation.firstName.maxLength,
              })}
            />
            <Form.Input
              label={t("common:last_name")}
              maxLength={customerValidation.lastName.maxLength}
              feedback={
                errors.lastName && (
                  <p className="text-error-500">{errors.lastName.message}</p>
                )
              }
              {...register("lastName", {
                required: { value: true, message: t("common:required") },
                validate: customerValidation.lastName.valid,
                maxLength: customerValidation.lastName.maxLength,
              })}
            />

            <p className="caption-3">
              {t("common:enter_name_as_displayed_on_license")}
            </p>

            <Form.Input
              label={t("common:email")}
              maxLength={customerValidation.email.maxLength}
              feedback={
                errors.email && (
                  <p className="text-error-500">{errors.email.message}</p>
                )
              }
              {...register("email", {
                required: { value: true, message: t("common:required") },
                pattern: customerValidation.email.pattern,
                maxLength: customerValidation.email.maxLength,
              })}
            />
            <PhoneInput
              label={t("common:phone")}
              feedback={errors.phoneNumber?.message}
              status={errors.phoneNumber ? "error" : "default"}
              {...register("phoneNumber", {
                required: { value: true, message: t("common:required") },
                validate: {
                  minLength: (value) =>
                    customerValidation.phoneNumber.valid(
                      phoneUnMaskPipe(value)
                    ) || t("common:phone_min_length"),
                  validateAreaCode: (value) =>
                    customerValidation.phoneNumber.validAreaCode(
                      phoneUnMaskPipe(value)
                    ) || t("common:form_error_invalid_phone_number"),
                },
              })}
            />

            <Controller
              name="dateOfBirth"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t("common:required"),
                },
              }}
              render={({ field, fieldState: { error } }) => {
                const { onChange, value, ...fieldProps } = field;
                return (
                  <DatePicker
                    key="c2c-pre-qual-birthdate"
                    selected={value}
                    label={t("common:date_of_birth")}
                    popperPlacement="top-start"
                    onChange={onChange}
                    minDate={customerValidation.dateOfBirth.getMinDate(
                      LocalDate.now()
                    )}
                    maxDate={customerValidation.dateOfBirth.getMaxDate(
                      LocalDate.now()
                    )}
                    locale={locale}
                    status={error ? "error" : "default"}
                    feedback={error ? error.message : ""}
                    suppressHotjar
                    {...fieldProps}
                  />
                );
              }}
            />
          </div>
          <div className="mt-6">
            <SubStepButtons
              backButton={{ onClick: onBack }}
              alignment="justify-between"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default FinancingPreQualificationPersonalInfo;
