import Button from "@common/components/Button";
import Form from "@common/components/form";
import Listbox from "@common/components/form/Listbox";
import { useCallback, useEffect, useState } from "react";
import { usePhoneNumberMask } from "@common/hooks/useMask";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import useCreateLeadMutation from "@modules/leads/hooks/useCreateLeadMutation";
import useWindowDimensions from "@common/hooks/useWindowDimensions";
import { Trans, useTranslation } from "next-i18next";
import TextLink from "@common/components/TextLink";
import Snackbar from "@common/components/Snackbar";
import useGetLocations from "@modules/locations/hooks/useGetLocations";
import { ArrowRightIcon } from "@components/icons";
import FormContentHeader from "@modules/cms/components/FormContentHeader";
import classNames from "classnames";
import cloudflareImagesLoader from "@common/helpers/imageLoaders/cloudflareImagesLoader";
import Feedback from "@common/components/form/Feedback";
import { cleanParagraphTags } from "@common/helpers/cleanHtml";
import LoadingSpinner from "@common/components/LoadingSpinner";
import useUser from "@modules/user/hooks/useUser";
import Location from "@modules/locations/types/Location";
import {
  convertDealerLocationToMapLocation,
  findClosestLocation,
} from "@common/helpers/distanceMatrixHelper";
import customerValidation from "@common/helpers/validations/customerValidation";
import PhoneInput from "@common/components/form/PhoneInput";
import { BackgroundImageGroup } from "../types/BackgroundImageGroup";
import FormSelectorBackgroundImage from "./FormSelectorBackgroundImage";
import { sizeClass } from "../types/SizeClass";
import { DEFAULT_FORM_SELECTOR_BACKGROUND_WIDTH } from "../constants/BackgroundImages";
import { CmsBackgroundType } from "../types/CmsBackground";
import GetBackgroundStyle from "../helpers/getBackgroundStyle";

type Props = {
  title: string;
  description: string;
  campaignTag: string;
  dealerId: string | undefined;
  backgroundImageGroup?: BackgroundImageGroup;
  locationDropdown: {
    hideLocations?: boolean;
    vinsolutionsId?: number;
  };
  consentCheckbox: {
    isVisible?: boolean;
    consentCheckboxLabel?: string;
  };
  background: CmsBackgroundType;
};

type GiveawayFormInput = {
  firstname: string;
  lastname: string;
  email: string;
  phoneNumber: string;
  preferredLocation: { id: string; name: string };
  selectedAnswer: { id: string; name: string };
  consent: boolean;
};

const GiveawayForm = ({
  title,
  description,
  campaignTag,
  dealerId,
  backgroundImageGroup,
  locationDropdown,
  consentCheckbox,
  background,
}: Props) => {
  const { t } = useTranslation(["common"]);
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue,
  } = useForm<GiveawayFormInput>({
    mode: "onSubmit",
  });

  const question = t("common:giveaway_question");
  const answers = [
    {
      id: "As Soon As Possible",
      name: t("common:giveaway_as_soon_as_possible"),
    },
    { id: "1 to 3 Months", name: t("common:giveaway_one_to_three_months") },
    { id: "4 to 12 Months", name: t("common:giveaway_four_to_twelve_months") },
    { id: "More Than A Year", name: t("common:giveaway_more_than_a_year") },
  ];

  const { locations } = useGetLocations();
  const { user } = useUser();
  const [preferredLocation, setPreferredLocation] = useState<Location>();
  const [selectedAnswer, setSelectedAnswer] = useState();

  const setClosestLocationToCustomer = useCallback(() => {
    if (user && user.longitude && user.latitude) {
      const mapLocation = findClosestLocation(
        user.latitude,
        user.longitude,
        locations.map((l) => convertDealerLocationToMapLocation(l))
      );

      if (mapLocation) {
        const location = locations.find((l) => l.id === mapLocation.id);

        if (location) {
          setPreferredLocation(location);
          setValue("preferredLocation", location);
        }
      }
    }
  }, [locations, setValue, user]);

  const resetForm = useCallback(() => {
    reset({
      firstname: "",
      lastname: "",
      email: "",
      phoneNumber: "",
      preferredLocation,
      consent: false,
    });
    setSelectedAnswer(undefined);
    setPreferredLocation(undefined);
  }, [preferredLocation, reset]);

  useEffect(() => {
    const defaultLocation = locations.find((x) => x.id === dealerId);
    if (defaultLocation) {
      setPreferredLocation(defaultLocation);
      setValue("preferredLocation", defaultLocation);
    } else if (!defaultLocation && !preferredLocation) {
      setClosestLocationToCustomer();
    }
  }, [
    dealerId,
    locations,
    preferredLocation,
    setClosestLocationToCustomer,
    setValue,
  ]);

  const firstnameFeedback = errors.firstname && (
    <p>
      {errors.firstname.type === "required" &&
        t("common:form_error_required_first_name")}
    </p>
  );

  const lastnameFeedback = errors.lastname && (
    <p>
      {errors.lastname.type === "required" &&
        t("common:form_error_required_last_name")}
    </p>
  );

  const emailFeedback = errors.email && (
    <p>
      {errors.email.type === "required" &&
        t("common:form_error_required_email")}
    </p>
  );

  const preferredLocationFeedback = errors.preferredLocation && (
    <p>
      {errors.preferredLocation.type === "required" &&
        t("common:preferred_location_form_error_required")}
    </p>
  );

  const messageFeedback = errors.selectedAnswer && (
    <p>
      {errors.selectedAnswer.type === "required" &&
        t("common:form_error_required_answer")}
    </p>
  );

  const createLeadMutation = useCreateLeadMutation();

  const handleFormSubmit: SubmitHandler<GiveawayFormInput> = (
    formInput: GiveawayFormInput
  ) => {
    const vinSolutionsId =
      locationDropdown && locationDropdown.hideLocations
        ? locationDropdown.vinsolutionsId
        : undefined;

    if (preferredLocation || vinSolutionsId) {
      createLeadMutation.mutate(
        {
          firstName: formInput.firstname,
          lastName: formInput.lastname,
          phoneNumber: formInput.phoneNumber,
          email: formInput.email,
          originUrl: document.location.href,
          leadDescription: campaignTag,
          dealerId: preferredLocation?.id,
          vinSolutionsDealerId: vinSolutionsId,
          message: formInput?.selectedAnswer.id,
          userDataConsentGiven: true, // Form has a disclaimer, so we can assume consent is given.
        },
        {
          onSuccess: () => {
            resetForm();
            Snackbar.Pop({ message: t("common:giveaway_form_submit_success") });
          },
        }
      );
    }
  };

  const { unMaskPipe: phoneNumberUnMaskPipe } = usePhoneNumberMask();

  const [width, setWidth] = useState(0);
  const { width: w } = useWindowDimensions();

  useEffect(() => {
    setWidth(w);
  }, [w]);

  return (
    <div
      className="bg-white overflow-visible no-scrollbar relative bg-cover bg-center"
      style={GetBackgroundStyle(background)}
    >
      <LoadingSpinner isLoading={createLeadMutation.isPending} isOverlay />
      <div
        className={classNames({
          "bg-no-repeat": backgroundImageGroup?.image,
          "bg-[center_right_-16rem]":
            backgroundImageGroup?.imagePosition === "Right" &&
            width < sizeClass["2xl"] &&
            width >= sizeClass.xl,
          "bg-[center_right_-8rem]":
            backgroundImageGroup?.imagePosition === "Right" &&
            width < sizeClass["3xl"] &&
            width >= sizeClass["2xl"],
          "bg-[center_right_1rem]":
            backgroundImageGroup?.imagePosition === "Right" &&
            width < sizeClass["4xl"] &&
            width >= sizeClass["3xl"],
          "bg-[center_right_14rem]":
            backgroundImageGroup?.imagePosition === "Right" &&
            width >= sizeClass["4xl"],

          "bg-[center_left_-14rem]":
            backgroundImageGroup?.imagePosition === "Left" &&
            width < sizeClass["2xl"] &&
            width >= sizeClass.xl,
          "bg-[center_left_-6rem]":
            backgroundImageGroup?.imagePosition === "Left" &&
            width < sizeClass["3xl"] &&
            width >= sizeClass["2xl"],
          "bg-[center_left_2rem]":
            backgroundImageGroup?.imagePosition === "Left" &&
            width < sizeClass["4xl"] &&
            width >= sizeClass["3xl"],
          "bg-[center_left_16rem]":
            backgroundImageGroup?.imagePosition === "Left" &&
            width >= sizeClass["4xl"],
        })}
        style={
          width >= sizeClass.xl &&
          backgroundImageGroup?.image &&
          backgroundImageGroup.image.length > 0
            ? {
                backgroundImage: `url(${cloudflareImagesLoader({
                  src: backgroundImageGroup?.image[0].sourceUrl || "",
                  width: DEFAULT_FORM_SELECTOR_BACKGROUND_WIDTH,
                })})`,
              }
            : {}
        }
      >
        <div
          className={classNames(
            "relative max-w-page mx-auto lg:mx-0 xl:mx-auto flex items-center py-10 ",
            {
              "flex-col": backgroundImageGroup,
              "px-5 items-center lg:items-stretch":
                !backgroundImageGroup ||
                !backgroundImageGroup.image ||
                (backgroundImageGroup.image &&
                  backgroundImageGroup.image.length === 0),
              "lg:flex-row px-5 lg:pl-5 lg:pr-0 xl:px-5":
                backgroundImageGroup?.imagePosition === "Right" &&
                backgroundImageGroup.image &&
                backgroundImageGroup.image.length > 0,
              "lg:flex-row-reverse px-5 lg:pl-0 lg:pr-5 xl:px-5":
                backgroundImageGroup?.imagePosition === "Left" &&
                backgroundImageGroup.image &&
                backgroundImageGroup.image.length > 0,
            }
          )}
        >
          <div
            className={classNames("", {
              "flex-1 lg:basis-[60%] xl:basis-[50%]":
                backgroundImageGroup?.image,
            })}
          >
            <FormContentHeader title={title} description={description} />
            <form
              onSubmit={handleSubmit(handleFormSubmit)}
              className="my-5 flex flex-col gap-5 md:grid md:grid-cols-2"
            >
              <Form.Input
                id="firstName"
                autoComplete="given-name"
                label={t("common:first_name")}
                feedback={firstnameFeedback}
                status={errors.firstname ? "error" : "default"}
                maxLength={40}
                suppressHotjar
                {...register("firstname", {
                  required: true,
                })}
              />
              <Form.Input
                id="lastName"
                autoComplete="family-name"
                label={t("common:last_name")}
                feedback={lastnameFeedback}
                status={errors.lastname ? "error" : "default"}
                maxLength={40}
                suppressHotjar
                {...register("lastname", {
                  required: true,
                })}
              />
              <Form.Input
                id="email"
                autoComplete="email"
                label={t("common:email")}
                feedback={emailFeedback}
                status={errors.email ? "error" : "default"}
                type="email"
                maxLength={150}
                {...register("email", {
                  required: true,
                  pattern: {
                    value: customerValidation.email.pattern,
                    message: t("common:invalid_email"),
                  },
                })}
              />
              <PhoneInput
                id="phoneNumber"
                label={t("common:phone_number")}
                feedback={errors.phoneNumber?.message}
                status={errors.phoneNumber ? "error" : "default"}
                {...register("phoneNumber", {
                  required: {
                    value: true,
                    message: t("common:form_error_required_phone_number"),
                  },
                  validate: {
                    phoneNumberCharacters: (value: string) =>
                      customerValidation.phoneNumber.valid(
                        phoneNumberUnMaskPipe(value)
                      ) || t("common:form_error_invalid_phone_number"),
                    validateAreaCode: (value) =>
                      customerValidation.phoneNumber.validAreaCode(
                        phoneNumberUnMaskPipe(value)
                      ) || t("common:form_error_invalid_phone_number"),
                  },
                })}
              />
              {!locationDropdown.hideLocations && (
                <div className="col-span-2">
                  <Controller
                    control={control}
                    rules={{
                      onChange: (e) => setPreferredLocation(e.target.value),
                      required: true,
                    }}
                    name="preferredLocation"
                    render={({ field: { onChange } }) => (
                      <Listbox
                        feedback={preferredLocationFeedback}
                        status={errors.preferredLocation ? "error" : "default"}
                        displayButton={(item) =>
                          item?.name || t("common:dropdown_placeholder")
                        }
                        displayOption={(i) => i?.name || ""}
                        valueOption={(i) => i}
                        keyOption={(i) => i.id}
                        label={t("common:preferred_location")}
                        items={locations}
                        selectedItem={preferredLocation}
                        onChange={onChange}
                      />
                    )}
                  />
                </div>
              )}
              <div className="col-span-2">
                <Controller
                  control={control}
                  rules={{
                    onChange: (e) => setSelectedAnswer(e.target.value),
                    required: true,
                  }}
                  name="selectedAnswer"
                  render={({ field: { onChange } }) => (
                    <Listbox
                      feedback={messageFeedback}
                      status={errors.selectedAnswer ? "error" : "default"}
                      displayButton={(item) =>
                        item?.name || t("common:dropdown_placeholder")
                      }
                      displayOption={(i) => i?.name || ""}
                      valueOption={(i) => i}
                      keyOption={(i) => i.id}
                      label={question}
                      items={answers}
                      selectedItem={selectedAnswer}
                      onChange={onChange}
                    />
                  )}
                />
              </div>
              {consentCheckbox?.isVisible &&
                (consentCheckbox.consentCheckboxLabel !== null ? (
                  <Form.Checkbox
                    label={
                      <div
                        className="cleanedParagraph"
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                          __html: cleanParagraphTags(
                            consentCheckbox.consentCheckboxLabel as string
                          ),
                        }}
                      />
                    }
                    className="col-span-2"
                    {...register("consent", { required: true })}
                  />
                ) : (
                  <Form.Checkbox
                    label={t("common:general_form_agreement")}
                    className="col-span-2"
                    {...register("consent", { required: true })}
                  />
                ))}
              {errors.consent && (
                <Feedback status="error">
                  {t("common:form_error_required_consent")}
                </Feedback>
              )}
              <div className="flex flex-col gap-5 md:flex-row md:items-center md:col-span-2">
                <Button
                  isSubmit
                  buttonStyle="primary"
                  className="flex-shrink-0"
                  rightIcon={<ArrowRightIcon />}
                >
                  {t("common:submit")}
                </Button>
              </div>
              <div className="flex flex-col justify-center gap-5 md:flex-row md:items-center md:col-span-2">
                <Trans t={t} i18nKey="common:general_form_disclaimer">
                  <p className="italic text-xs">
                    <TextLink href="/privacy-policy">
                      {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                      <a target="_blank" />
                    </TextLink>
                    <TextLink href="/terms-conditions">
                      {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                      <a target="_blank" />
                    </TextLink>
                  </p>
                </Trans>
              </div>
            </form>
          </div>
          {backgroundImageGroup &&
            backgroundImageGroup.image &&
            backgroundImageGroup.image.length > 0 && (
              <FormSelectorBackgroundImage
                backgroundImageGroup={backgroundImageGroup}
              />
            )}
        </div>
      </div>
    </div>
  );
};

export default GiveawayForm;
