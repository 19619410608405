import { Translation } from "@common/types/Translation";
import classNames from "classnames";
import { ChangeEvent, ReactNode } from "react";

interface Item {
  id: number;
  name: Translation;
  order: number;
}

type Props = {
  id: string;
  children: ReactNode;
  className?: string;
  labelDisplay?: "flex" | "inline-block";
  objectValue: Item;
  onChangeEvent: (
    event: ChangeEvent<HTMLInputElement>,
    objectValue: Item
  ) => void;
  isChecked: boolean;
};

const DivCheckbox = ({
  id,
  children,
  className,
  objectValue,
  onChangeEvent,
  isChecked,
  labelDisplay = "flex",
}: Props) => {
  return (
    <div className="relative">
      <input
        id={id}
        type="checkbox"
        className="opacity-0 absolute left-0 top-0 peer"
        onChange={(event) => onChangeEvent(event, objectValue)}
        checked={isChecked}
      />
      <label
        htmlFor={id}
        className={classNames(
          "justify-center items-center text-center content-center w-full h-full peer-checked:border-primary-bright peer-checked:ring-1 peer-checked:ring-offset-0 peer-checked:ring-primary-bright peer-checked:shadow-none peer-checked:text-primary-bold peer-checked:[&>svg]:text-persianBlue lg:hover:border-eggBlue peer-focus:border-eggBlue rounded-[4px] border-gray-50 border select-none cursor-pointer py-3",
          className,
          labelDisplay
        )}
      >
        {children}
      </label>
    </div>
  );
};

export default DivCheckbox;
