import { Trans, useTranslation } from "next-i18next";
import { SubmitHandler, useFormContext } from "react-hook-form";
import SubStepButtons from "@common/components/form/SubStepButtons";
import MoneyInput from "@common/components/form/MoneyInput";
import { useRouter } from "next/router";
import { useNumberMask, usePhoneNumberMask } from "@common/hooks/useMask";
import Link from "next/link";
import { ChangeEvent, useState } from "react";
import Form from "@common/components/form";
import useCreateConsumerFinancingPreQualificationMutation from "@modules/purchase/hooks/useCreateConsumerFinancingPreQualificationMutation";
import useConsumerFinancingQuery from "@modules/purchase/hooks/useConsumerFinancingFromQuery";
import { LocalDate, ZoneId, nativeJs } from "@js-joda/core";
import useConsumerFinancing, {
  checkActiveConsumerFinancing,
} from "@modules/purchase/hooks/useConsumerFinancing";
import useAbandonConsumerFinancingMutation from "@modules/purchase/hooks/useAbandonConsumerFinancingMutation";
import { getFirstStep } from "@modules/purchase/types/PurchaseStep";
import { useRawBuyerInfoFromQuery } from "@modules/query-string/hooks/useBuyerInfoFromQuery";
import LoadingSpinner from "@common/components/LoadingSpinner";
import {
  FinancingPreQualificationMode,
  residenceinfoMode,
} from "./types/FinancingPreQualificationMode";
import { PreQualificationFormProps } from "./types/FinancingPreQualificationFormProps";

type Props = {
  onChangeMode(newMode: FinancingPreQualificationMode): void;
};

const FinancingPreQualificationFinancialInfo = ({ onChangeMode }: Props) => {
  const { t } = useTranslation(["common", "purchase"]);
  const router = useRouter();
  const { source } = router.query;

  const { locale } = useRouter();

  const createConsumerFinancingPreQualificationMutation =
    useCreateConsumerFinancingPreQualificationMutation();

  const { unMaskPipe: unMaskPhoneNumberPipe } = usePhoneNumberMask();

  const { unMaskPipe: unMaskNumberPipe } = useNumberMask();

  const [showOverlay, setShowOverlay] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useFormContext<PreQualificationFormProps>();

  const abandonConsumerFinancingMutation =
    useAbandonConsumerFinancingMutation();

  const buyerInfoParams = useRawBuyerInfoFromQuery();

  const consumerFinancingQuery = useConsumerFinancingQuery();

  const { consumerFinancing: consumerFinancingOrNotFound } =
    useConsumerFinancing();

  const isConsumerFinancingFound = checkActiveConsumerFinancing(
    consumerFinancingOrNotFound
  );

  const startFinancingApplication = (form: PreQualificationFormProps) => {
    createConsumerFinancingPreQualificationMutation.mutate(
      {
        firstName: form.firstName,
        lastName: form.lastName,
        email: form.email,
        phone: unMaskPhoneNumberPipe(form.phoneNumber),
        // specifying UTC fixes a bug that could adjust the date to the previous day
        dateOfBirth: LocalDate.from(nativeJs(form.dateOfBirth, ZoneId.UTC)),
        streetAddress: form.address,
        city: form.city,
        province: form.province,
        postalCode: form.postalCode,
        vehiclePrice: unMaskNumberPipe(form.vehiclePrice),
        annualIncome: unMaskNumberPipe(form.annualIncome),
        totalMonthlyDebts: unMaskNumberPipe(form.totalMonthlyDebts),
        isConsentGiven: form.consent,
        odometer: consumerFinancingQuery.mileage ?? 0,
        year: consumerFinancingQuery.year ?? 0,
        make: consumerFinancingQuery.make ?? "",
        model: consumerFinancingQuery.model ?? "",
        trim: consumerFinancingQuery.trim ?? "",
        vin: consumerFinancingQuery.vin ?? "",
        vehicleCoverImage: consumerFinancingQuery.coverImage ?? "",
        source: consumerFinancingQuery.source ?? "",
        sourceAdId: consumerFinancingQuery.sourceAdId ?? "",
        sourceAppId: consumerFinancingQuery.sourceAppId ?? "",
        sourceLocationId: consumerFinancingQuery.sourceLocationId ?? "",
        sourceSessionId: consumerFinancingQuery.sourceSessionId ?? "",
        sourceUserId: consumerFinancingQuery.sourceUserId ?? "",
        sourceCtaId: consumerFinancingQuery.sourceCtaId ?? "",
      },
      {
        onSuccess: () => {
          router.push({
            pathname: getFirstStep("financing").introHref,
            query: {
              source,
              ...buyerInfoParams,
            },
          });
          setShowOverlay(false);
        },
        onError: () => {
          setShowOverlay(false);
        },
      }
    );
  };

  const onFormSubmit: SubmitHandler<PreQualificationFormProps> = (
    form: PreQualificationFormProps
  ) => {
    setShowOverlay(true);
    if (isConsumerFinancingFound) {
      abandonConsumerFinancingMutation.mutate(undefined, {
        onSuccess: () => startFinancingApplication(form),
      });
    } else {
      startFinancingApplication(form);
    }
  };

  const onBack: () => void = () => {
    onChangeMode(residenceinfoMode);
  };

  const { maskPipe: maskNumberPipe } = useNumberMask();

  const handleCurrencyChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.value = maskNumberPipe(e.target.value);
  };

  return (
    <>
      <LoadingSpinner isLoading={showOverlay} isOverlay />
      <div className="mx-auto mb-5 lg:w-5/6 md:w-3/4 sm:w-4/5">
        <div>
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <div className="flex flex-col gap-3">
              <MoneyInput
                isForm
                label={t("purchase:vehicle_price")}
                status={errors.vehiclePrice ? "error" : "default"}
                feedback={errors.vehiclePrice?.message}
                locale={locale || ""}
                maxLength={11}
                suppressHotjar
                register={register("vehiclePrice", {
                  required: {
                    value: true,
                    message: t("common:required"),
                  },
                  minLength: 1,
                  maxLength: 11,
                  onChange: handleCurrencyChange,
                })}
              />
              <MoneyInput
                isForm
                label={t("common:annual_income")}
                status={errors.annualIncome ? "error" : "default"}
                feedback={errors.annualIncome?.message}
                locale={locale || ""}
                maxLength={11}
                suppressHotjar
                register={register("annualIncome", {
                  required: {
                    value: true,
                    message: t("common:required"),
                  },
                  minLength: 1,
                  maxLength: 11,
                  onChange: handleCurrencyChange,
                })}
              />

              <MoneyInput
                isForm
                label={t("purchase:total_monthly_debts")}
                status={errors.totalMonthlyDebts ? "error" : "default"}
                feedback={errors.totalMonthlyDebts?.message}
                locale={locale || ""}
                maxLength={11}
                suppressHotjar
                register={register("totalMonthlyDebts", {
                  required: {
                    value: true,
                    message: t("common:required"),
                  },
                  minLength: 1,
                  maxLength: 11,
                  onChange: handleCurrencyChange,
                })}
              />
              <p className="caption-3">
                {t("purchase:enter_approximate_amount_major_expenses")}
              </p>
              <Form.Checkbox
                label={
                  <span className="caption-1 font-medium">
                    <Trans
                      t={t}
                      i18nKey="common:general_terms_and_conditions"
                      components={{
                        a: (
                          <Link
                            className="text-primary-bold underline"
                            href="/privacy-policy"
                            target="_blank"
                          />
                        ),
                        b: (
                          <Link
                            className="text-primary-bold underline"
                            href="/terms-conditions"
                            target="_blank"
                          />
                        ),
                      }}
                    />
                  </span>
                }
                feedback={
                  errors.consent?.type === "required" && (
                    <p className="text-error-500">
                      {t("common:form_error_required_consent")}
                    </p>
                  )
                }
                status={errors.consent ? "error" : "default"}
                {...register("consent", { required: true })}
              />
            </div>
            <div className="mt-6">
              <SubStepButtons
                backButton={{ onClick: onBack }}
                alignment="justify-between"
                nextButtonText={t("common:submit")}
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default FinancingPreQualificationFinancialInfo;
