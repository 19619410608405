import api from "@modules/api/api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { LocalDate } from "@js-joda/core";
import purchaseQueryKeys from "../purchaseQueryKeys";

type PostBody = {
  /** from form inputs */
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  dateOfBirth: LocalDate;
  streetAddress: string;
  city: string;
  province: string;
  postalCode: string;
  vehiclePrice: number;
  annualIncome: number;
  totalMonthlyDebts: number;
  isConsentGiven: boolean;

  /** from query params */
  odometer: number;
  year?: number | null;
  make?: string | null;
  model?: string | null;
  trim?: string | null;
  vin?: string | null;
  source?: string | null;
  vehicleCoverImage?: string | null;
  sourceAdId?: string | null;
  sourceAppId?: string | null;
  sourceLocationId?: string | null;
  sourceSessionId?: string | null;
  sourceUserId?: string | null;
  sourceCtaId?: string | null;
};

const postCreateConsumerFinancingPreQualification = async (req: PostBody) => {
  return api.rawPost(`api/purchases/consumer-financing-pre-qualification`, {
    json: req,
  });
};

export default function useCreateConsumerFinancingPreQualificationMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: postCreateConsumerFinancingPreQualification,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: purchaseQueryKeys.activeFinancingPurchase(),
      });
    },
  });
}
