import { useTranslation, Trans } from "next-i18next";
import { useRouter } from "next/router";
import Button from "@common/components/Button";
import LocalImage from "@common/components/LocalImage";
import { ImageWithTranslation } from "@common/types/ImageWithTranslation";
import classNames from "classnames";
import { ArrowRightIcon } from "@common/components/icons";
import cloudflareImagesLoader from "@common/helpers/imageLoaders/cloudflareImagesLoader";
import { DEFAULT_BACKGROUND_WIDTH } from "@modules/cms/constants/BackgroundImages";
import useVehicleInfoFromQuery from "@modules/query-string/hooks/useVehicleInfoFromQuery";
import { useCallback, useContext, useState } from "react";
import LoginModalContext from "@modules/user/LoginModalContext";
import useUser from "@modules/user/hooks/useUser";
import DealTypeEnum from "@modules/admin/deals/enums/DealTypeEnum";
import { InstantCashOfferHeadingLayout } from "@modules/cms/types/InstantCashOfferHeadingLayout";
import {
  IcoBubblesEn,
  IcoBubblesFr,
  InstantCashOfferHeroEn,
  InstantCashOfferHeroFr,
} from "@public/images/instant-cash-offer";
import PurchaseSourceVehicleImage from "@modules/purchase/components/PurchaseSourceVehicleImage";
import useAssociateTradeInMutation from "@modules/purchase/hooks/useAssociateTradeInMutation";
import { ICO_INQUIRY_EMAIL } from "@common/constants";
import {
  getStepById,
  icoPersonalDetailsStep,
} from "@modules/purchase/types/PurchaseStep";
import useGetPurchasesByDealIds from "@modules/purchase/hooks/useGetPurchasesByDealIds";
import getUserDealIdsByType from "@modules/purchase/helpers/getUserDealIdsByType";
import HTTPErrorWithBody from "@modules/api/HTTPErrorWithBody";
import useUtmParamsFromQuery, {
  getSetUtmParams,
} from "@modules/query-string/hooks/useUtmParamsFromQuery";
import { gtmPushData } from "@common/helpers/gtm";
import useScannedTradeInImages from "@modules/trade/hooks/useScannedTradeInImages";
import InstantCashOfferNotFoundModal from "./InstantCashOfferNotFoundModal";
import DealCreatedForOfferModal from "./DealCreatedForOfferModal";

type Props = {
  layout: InstantCashOfferHeadingLayout;
};

const vehicleDefaultImage: ImageWithTranslation = {
  srcEn: InstantCashOfferHeroEn,
  srcFr: InstantCashOfferHeroFr,
  altKey: "purchase:instant_cash_offer_header_image",
};

const bubblesImage: ImageWithTranslation = {
  srcEn: IcoBubblesEn,
  srcFr: IcoBubblesFr,
  altKey: "purchase:instant_cash_offer_header_image",
};

const InstantCashOfferHeading = ({ layout }: Props) => {
  const { t } = useTranslation(["common", "purchase"]);
  const router = useRouter();
  const { locale, query } = router;
  const { background } = layout;
  const { year, make, model, coverImage } = useVehicleInfoFromQuery();
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [coverImageFailedToLoad, setCoverImageFailedToLoad] = useState(false);
  const [isDealCreatedForOfferModalOpen, setIsDealCreatedForOfferModalOpen] =
    useState(false);

  const { setShowModal: setShowLoginModal } = useContext(LoginModalContext);
  const { user, isLoading } = useUser();

  const dealIds = getUserDealIdsByType(user, DealTypeEnum.BuyIn);
  const { allPurchases, isLoading: isPurchasesLoading } =
    useGetPurchasesByDealIds(dealIds);
  const purchase = allPurchases[0];
  const tradeIn =
    purchase?.tradeIns && purchase.tradeIns.length > 0
      ? purchase.tradeIns[0]
      : null;

  const { data: scannedTradeInImages } = useScannedTradeInImages(
    query.tradeIn as string,
    query.source as string,
    !!query.tradeIn && !!query.source && !!user && user.isLoggedIn
  );

  const savedCoverImageId = scannedTradeInImages
    ? scannedTradeInImages?.imageList[0]
    : tradeIn?.imageList?.split(",")[0];

  const tradeInId = scannedTradeInImages?.tradeInId ?? tradeIn?.id;
  const savedCoverImageSrc = `${process.env.NEXT_PUBLIC_API}/api/trade-in/${tradeInId}/vehicle/image?vehicleImageId=${savedCoverImageId}`;

  const { mutate: associateTradeInMutation } = useAssociateTradeInMutation();
  const utmParams = useUtmParamsFromQuery();

  const handleOnCtaClick = useCallback(() => {
    if (!user?.isLoggedIn) {
      gtmPushData({
        event: "ICO",
        description: "ICO Signup/Login",
      });

      setShowLoginModal(true);
    } else if (query.tradeIn && query.source) {
      gtmPushData({
        event: "ICO",
        description: "View Your ICO",
      });

      // if trade-in token exists, always try to associate it with the user
      associateTradeInMutation(
        {
          tradeInToken: query.tradeIn as string,
          source: query.source as string,
        },
        {
          onSuccess: (response) => {
            router.push({
              pathname: icoPersonalDetailsStep.href,
              query: {
                dealId: response.dealId,
                ...getSetUtmParams(utmParams),
              },
            });
          },
          onError: (error) => {
            if (error instanceof HTTPErrorWithBody) {
              const err = error as HTTPErrorWithBody;

              if (err.response.status === 400) {
                setIsDealCreatedForOfferModalOpen(true);
              } else {
                setShowErrorModal(true);
              }
            }
          },
        }
      );
    } else if (allPurchases.length === 1 && purchase) {
      const currentStep =
        (purchase?.currentStepId
          ? getStepById("ico", purchase.currentStepId)
          : icoPersonalDetailsStep) ?? icoPersonalDetailsStep;
      router.push({
        pathname: currentStep.href,
        query: {
          dealId: purchase.id,
        },
      });
    } else if (dealIds.length > 1) {
      // if user has a deal and there is no trade-in token in URL, redirect to their ico offers
      router.push(
        {
          pathname: "/profile/my-offers",
          query: router.query,
        },
        "/profile/my-offers",
        {
          locale: "en",
        }
      );
    } else {
      // if user is logged in but doesn't have a deal, send them to Kijiji landing page to create an ico
      router.push({
        pathname: process.env.NEXT_PUBLIC_KIJIJI_ICO_LANDING_PAGE,
        query: router.query,
      });
    }
  }, [
    allPurchases.length,
    associateTradeInMutation,
    dealIds.length,
    purchase,
    query.source,
    query.tradeIn,
    router,
    setShowLoginModal,
    user?.isLoggedIn,
    utmParams,
  ]);

  const showCoverImage =
    (!!coverImage || !!savedCoverImageId) && !coverImageFailedToLoad;

  return (
    <>
      <div
        id="cms-financing-heading-section"
        className="bg-white overflow-visible no-scrollbar relative bg-cover bg-center"
        style={{
          backgroundColor:
            (background.option === "Color" && background.backgroundColor) ||
            "transparent",
          backgroundImage: `url(${cloudflareImagesLoader({
            src:
              (background.option === "Image" &&
                background.backgroundImage &&
                background.backgroundImage[0].sourceUrl) ||
              "",
            width: DEFAULT_BACKGROUND_WIDTH,
          })})`,
        }}
      >
        <div
          className={classNames(
            "relative max-w-page mx-auto flex items-center flex-col-reverse pt-10 pb-6 px-4 lg:px-5 lg:py-16 lg:flex-row-reverse"
          )}
        >
          <div className="flex-1 lg:basis-[45%] lg:px-0 md:px-28">
            <div className="w-full items-center justify-center text-center lg:text-left lg:items-start lg:justify-start">
              <p className="text-white font-semibold text-[3.25rem] leading-[3.625rem]">
                {year && make && model ? (
                  <Trans
                    t={t}
                    i18nKey="purchase:finalize_your_instant_cash_offer"
                    values={{
                      vehicle: `${year} ${make} ${model}`,
                    }}
                  />
                ) : (
                  t("purchase:you_are_almost_there")
                )}
              </p>
            </div>

            <div className="mt-6 w-full items-center justify-center text-center lg:text-left lg:items-start lg:justify-start text-white text-2xl">
              {t("purchase:securely_sell_your_vehicle_online")}
            </div>

            <div className="flex flex-col gap-2 mt-8 justify-center text-center md:items-center lg:text-start lg:items-start lg:justify-start">
              <Button
                rightIcon={<ArrowRightIcon />}
                onClick={handleOnCtaClick}
                buttonStyle="primary-light"
                spacing="tight-hug"
                disabled={isLoading || isPurchasesLoading}
              >
                {user?.isLoggedIn
                  ? t("purchase:view_your_ico")
                  : t("common:create_account_or_login")}
              </Button>
            </div>
          </div>
          <div className="flex-1 lg:basis-[55%] w-full relative">
            <div className="min-w-full h-auto relative inset-0">
              {showCoverImage ? (
                <div id="ico-vehicle-image">
                  <div className="absolute -right-4 md:-right-1 lg:-right-14 xl:-right-10 z-[2]">
                    <LocalImage
                      className="h-48 w-40 md:h-96 md:w-96 object-contain rounded-lg"
                      src={
                        locale === "fr"
                          ? bubblesImage.srcFr
                          : bubblesImage.srcEn
                      }
                      alt={t(bubblesImage.altKey)}
                    />
                  </div>

                  <div className="flex justify-center overflow-hidden relative md:h-full">
                    <div className="rounded-full border-white border-[.5rem] mr-9 md:mr-32 lg:mr-44 h-80 w-80 md:border-[1rem] md:h-[27rem] md:w-[27rem] lg:h-[30rem] lg:w-[30rem]">
                      <PurchaseSourceVehicleImage
                        className="rounded-full w-full h-full object-cover"
                        src={
                          coverImage ||
                          (savedCoverImageId ? savedCoverImageSrc : "")
                        }
                        alt={t(vehicleDefaultImage.altKey)}
                        onError={() => setCoverImageFailedToLoad(true)}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  id="ico-vehicle-local-image"
                  className="overflow-auto relative h-56 md:h-96 lg:mr-8"
                >
                  <LocalImage
                    className="z-[2] w-full object-contain h-auto rounded-lg"
                    src={
                      locale === "fr"
                        ? vehicleDefaultImage.srcFr
                        : vehicleDefaultImage.srcEn
                    }
                    alt={t(vehicleDefaultImage.altKey)}
                    fill
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <InstantCashOfferNotFoundModal
        showErrorModal={showErrorModal}
        setShowErrorModal={setShowErrorModal}
        onPrimaryClick={() => {
          setShowErrorModal(false);
          router.push(`mailto:${ICO_INQUIRY_EMAIL}`);
        }}
        onSecondaryClick={() => setShowErrorModal(false)}
      />
      <DealCreatedForOfferModal
        isOpen={isDealCreatedForOfferModalOpen}
        onClose={() => setIsDealCreatedForOfferModalOpen(false)}
      />
    </>
  );
};

export default InstantCashOfferHeading;
