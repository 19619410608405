import { useTranslation } from "next-i18next";
import { useEffect } from "react";
import { gtmPushData } from "@common/helpers/gtm";
import useUser from "@modules/user/hooks/useUser";
import UnexpectedErrorModal from "../UnexpectedErrorModal";

type Props = {
  showErrorModal: boolean;
  setShowErrorModal: (show: boolean) => void;
  onPrimaryClick: () => void;
  onSecondaryClick: () => void;
};

const InstantCashOfferNotFoundModal = ({
  showErrorModal,
  setShowErrorModal,
  onPrimaryClick,
  onSecondaryClick,
}: Props) => {
  const { t } = useTranslation(["common", "purchase"]);

  const { user } = useUser();

  useEffect(() => {
    if (showErrorModal) {
      gtmPushData({
        event: "ICO",
        description: "ICO Cash Offer Not Found",
        element: "ICO Landing Page",
        user_id: user?.id,
      });
    }
  }, [showErrorModal, user?.id]);

  return (
    <UnexpectedErrorModal
      showUnexpectedErrorModal={showErrorModal}
      setShowUnexpectedErrorModal={setShowErrorModal}
      onPrimaryClick={onPrimaryClick}
      onSecondaryClick={onSecondaryClick}
      title={t("purchase:no_ico_found")}
      heading={t("common:hmm")}
      subheading={t("purchase:account_not_associated_with_ico")}
      body={t("purchase:ico_please_contact_us")}
      primaryButtonText={t("common:contact_us")}
      secondaryButtonText={t("common:cancel")}
    />
  );
};

export default InstantCashOfferNotFoundModal;
