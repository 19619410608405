import useAftermarketPurchase from "@modules/purchase/hooks/useAftermarketPurchase";
import useAftermarketPurchaseFromQuery from "@modules/purchase/hooks/useAftermarketPurchaseFromQuery";
import { useRouter } from "next/router";
import { useEffect, useRef } from "react";
import { gtmPushData } from "@common/helpers/gtm";
import useSourceInfoFromQuery from "@modules/query-string/hooks/useSourceInfoFromQuery";
import useBuyerInfoFromQuery from "@modules/query-string/hooks/useBuyerInfoFromQuery";
import useExtendedWarrantyDefaultRates from "@modules/purchase/hooks/useExtendedWarrantyDefaultRates";
import useIsBuyerProvinceActiveForAftermarket from "@modules/purchase/hooks/useIsBuyerProvinceActiveForAftermarket";
import { tryGetActiveAftermarketPurchase } from "@modules/purchase/types/Purchase";
import validateRequiredRatesCallFields from "@modules/purchase/helpers/validateRequiredRatesCallFields";
import { WarrantySelectCoverageLayout } from "@modules/cms/types/WarrantySelectCoverageLayout";
import { useWarrantyQuotePageContext } from "@modules/purchase/WarrantyQuotePageContext";
import GetBackgroundStyle from "@modules/cms/helpers/getBackgroundStyle";
import WarrantySelectCoverageCards from "./WarrantySelectCoverageCards";
import WarrantySelectCoverageHeading from "./WarrantySelectCoverageHeading";
import { CoverageSearchBy } from "./AvailableCoveragesModal";

const WarrantySelectCoverage = ({
  layout,
}: {
  layout: WarrantySelectCoverageLayout;
}) => {
  const { showHeading, background } = layout;
  const router = useRouter();
  const { locale, query } = router;
  const viewMode = query.view === "quote" ? "quote" : undefined;
  const warrantyOptionsSectionRef = useRef<HTMLDivElement>(null);

  const aftermarketPurchaseFromQuery = useAftermarketPurchaseFromQuery();
  const { purchase: purchaseResponse } = useAftermarketPurchase();
  const purchase = tryGetActiveAftermarketPurchase(purchaseResponse);
  const { source: querySource } = useSourceInfoFromQuery();
  const source = purchase?.dealSource || querySource;

  const { province } = useBuyerInfoFromQuery();
  const { isProvinceActive, isProvincesLoading } =
    useIsBuyerProvinceActiveForAftermarket(purchase?.dealSource, province);

  const {
    setShowCoveragesModal,
    setCoveragesModalSearchType,
    onSelectCoverage,
    onResumePurchase,
    purchasedVehicle,
    isSubmitted,
  } = useWarrantyQuotePageContext();

  const handleOpenCoveragesModal = (
    element: string,
    searchType?: CoverageSearchBy
  ) => {
    gtmPushData({
      event: "ManualQuoteLookup",
      element,
      description: `${element} Button Clicked`,
      source: purchase?.dealSource || "",
    });

    setShowCoveragesModal(true);
    setCoveragesModalSearchType(searchType);
  };

  const {
    vin,
    mileage,
    year,
    make,
    model,
    trim,
    coverImage: coverImageParam,
    fuelType,
  } = aftermarketPurchaseFromQuery;

  const { data: extDefaultRates, isSuccess: isRatesAvailable } =
    useExtendedWarrantyDefaultRates(
      {
        vin: vin ?? "",
        odometer: mileage as number,
        province: province?.abbr || undefined,
        source: source ?? undefined,
        year: year?.toString() ?? "",
        make: make ?? "",
        model: model ?? "",
        trim: trim ?? "",
        fuelType: fuelType ?? "",
      },
      isProvincesLoading ? null : isProvinceActive,
      locale || "en"
    );

  const scrollToWarrantyOptionsSection = () => {
    warrantyOptionsSectionRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const handleCustomEvent = () => {
      scrollToWarrantyOptionsSection();
    };
    if (showHeading) {
      // Add an event listener for the custom event, if the layout has a heading
      window.addEventListener(
        "scrollToWarrantyOptionsSection",
        handleCustomEvent
      );
    }

    return () => {
      if (showHeading) {
        window.removeEventListener(
          "scrollToWarrantyOptionsSection",
          handleCustomEvent
        );
      }
    };
  }, [showHeading]);

  const getVehicleCoverImage = (): string => {
    if (!purchase) {
      return coverImageParam ?? "";
    }

    const existingVehicle = purchase.vehicle;
    const savedCoverImage = existingVehicle?.image;
    const isVinMatch = existingVehicle?.vin === vin;
    const areRequiredFieldsAvailable = validateRequiredRatesCallFields({
      vin,
      year: year ? year.toString() : "",
      make,
      model,
      odometer: mileage,
    });

    if (!areRequiredFieldsAvailable) {
      return savedCoverImage ?? "";
    }

    return isVinMatch ? savedCoverImage ?? "" : coverImageParam ?? "";
  };

  return (
    <div
      id="cms-warranty-select-coverage-section"
      style={
        isRatesAvailable
          ? GetBackgroundStyle(background)
          : { backgroundColor: background.backgroundColor ?? "transparent" }
      }
    >
      {showHeading && (
        <WarrantySelectCoverageHeading
          isRatesAvailable={isRatesAvailable}
          hasNoExistingPuchase={
            purchase === undefined || purchase.products.length === 0
          }
          vehicleInfo={purchasedVehicle}
          vehicleCoverImage={getVehicleCoverImage()}
          onResumeBtnCLick={onResumePurchase}
          onLookUpWarrantyBtnClick={handleOpenCoveragesModal}
        />
      )}

      {isRatesAvailable && (
        <div className="overflow-visible no-scrollbar relative bg-cover bg-center">
          <div className="max-w-page mx-auto pb-6 px-4 lg:px-5 lg:pb-16 lg:pt-2">
            <div
              ref={warrantyOptionsSectionRef}
              className="lg:pt-8 text-center"
            >
              {/** Warranty Select Section */}
              <WarrantySelectCoverageCards
                coverages={extDefaultRates}
                onSelectCoverage={onSelectCoverage}
                onQuoteDifferentVehicle={(searchType) =>
                  handleOpenCoveragesModal(
                    "Quote a different vehicle",
                    searchType
                  )
                }
                showEstimateMessaging={vin == null}
                disabled={isSubmitted}
                viewMode={viewMode}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WarrantySelectCoverage;
