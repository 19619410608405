import { useNumberMask } from "@common/hooks/useMask";
import useVehicleInfoFromQuery from "@modules/query-string/hooks/useVehicleInfoFromQuery";
import { WarrantyGraphLayout } from "@modules/cms/types/WarrantyGraphLayout";
import useAftermarketPurchase from "@modules/purchase/hooks/useAftermarketPurchase";
import useBuyerInfoFromQuery from "@modules/query-string/hooks/useBuyerInfoFromQuery";
import useIsBuyerProvinceActiveForAftermarket from "@modules/purchase/hooks/useIsBuyerProvinceActiveForAftermarket";
import useExtendedWarrantyDefaultRates from "@modules/purchase/hooks/useExtendedWarrantyDefaultRates";
import useSourceInfoFromQuery from "@modules/query-string/hooks/useSourceInfoFromQuery";
import { tryGetActiveAftermarketPurchase } from "@modules/purchase/types/Purchase";
import { useRouter } from "next/router";
import GetBackgroundStyle from "@modules/cms/helpers/getBackgroundStyle";
import { useEffect, useRef } from "react";
import WarrantyGraphChart from "./WarrantyGraphChart";
import RemainingWarranty from "./RemainingWarranty";

type Props = {
  layout: WarrantyGraphLayout;
};

const WarrantyGraph = ({ layout }: Props) => {
  const { background } = layout;
  const { unMaskPipe: unMaskNumberPipe } = useNumberMask();
  const { vin, odometer, year, make, model, trim, fuelType } =
    useVehicleInfoFromQuery();
  const { province } = useBuyerInfoFromQuery();
  const { purchase: purchaseResponse } = useAftermarketPurchase();
  const purchase = tryGetActiveAftermarketPurchase(purchaseResponse);
  const { source: querySource } = useSourceInfoFromQuery();
  const source = purchase?.dealSource || querySource;
  const { isProvinceActive, isProvincesLoading } =
    useIsBuyerProvinceActiveForAftermarket(purchase?.dealSource, province);
  const { locale } = useRouter();

  const warrantyGraphSectionRef = useRef<HTMLDivElement>(null);

  const { data: defaultRates } = useExtendedWarrantyDefaultRates(
    {
      vin: vin ?? "",
      odometer: unMaskNumberPipe((odometer as string) ?? ""),
      province: province?.abbr,
      source: source ?? undefined,
      year: year?.toString() ?? "",
      make: make ?? "",
      model: model ?? "",
      trim: trim ?? "",
      fuelType: fuelType ?? "",
    },
    isProvincesLoading ? null : isProvinceActive,
    locale || "en"
  );

  const vehicleWarranty = defaultRates?.vehicleWarranty;
  const isVehicleWarrantyFetched = !!vehicleWarranty;

  const scrollToWarrantyComponentsSection = () => {
    warrantyGraphSectionRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const handleCustomEvent = () => {
      scrollToWarrantyComponentsSection();
    };
    if (warrantyGraphSectionRef) {
      window.addEventListener(
        "scrollToWarrantyGraphSection",
        handleCustomEvent
      );
    }

    return () => {
      if (warrantyGraphSectionRef) {
        window.removeEventListener(
          "scrollToWarrantyGraphSection",
          handleCustomEvent
        );
      }
    };
  }, []);

  return vehicleWarranty && vin ? (
    <div
      id="cms-warranty-graph-section"
      className="bg-white overflow-visible no-scrollbar relative bg-cover bg-center"
      style={GetBackgroundStyle(background)}
      ref={warrantyGraphSectionRef}
    >
      <div className="max-w-page mx-auto pt-10 pb-6 px-4 lg:px-5 lg:py-16 min-h-[300px]">
        <div className="py-8 mx-auto text-center">
          <RemainingWarranty vehicleWarranty={vehicleWarranty} />
        </div>
        <div className="flex justify-center">
          <div className="w-full lg:w-5/6">
            <WarrantyGraphChart
              vehicleWarranty={vehicleWarranty}
              redraw={isVehicleWarrantyFetched}
            />
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default WarrantyGraph;
